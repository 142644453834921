.MediaContainer {
    height: 20%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

svg.Icons {
    color: #1283B3;
    font-size: 3rem;
}

/* 481px—768px: iPads, Tablets */
@media (min-width: 481px) {
    svg.Icons {
        font-size: 4rem;
    }

    

}
/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {  


    svg.Icons {
        font-size: 6rem;
    }

    .MediaContainer {

        flex-direction: column;
        padding-right: 40px;

    }

} 