.About {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PhotoSkillsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

/* 481px—768px: iPads, Tablets */
@media (min-width: 481px) {
  .PhotoSkillsContainer {
    max-width: 500px;
  }

  .PhotoSkillsContainer {
    padding-top: 20px;
  }
}

/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {
  .PhotoSkillsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
    padding-top: 200px;
  }
}
