/* Mobile first  320px—480px: Mobile devices*/
.CardContainer {
  width: 100%;
  height: 200px;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

.Card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transition: transform 1s;
  -webkit-transition: -webkit-transform 1s;

  /* box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ; */
}

.CardContainer:hover .Card {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

/* to have the card effect in iphones  */
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .CardContainer:hover .Card {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

}

.Front,
.Back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  /* box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ; */
}

.Front {
  background-color: #ffc728;
  color: #84670f;
  font-size: 1.5rem;
}

.Back {
  background-color: #fafafa;
  color: #84670f;
  font-size: 0.7rem;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.Description {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #686868;
  line-height: 18pt;
  text-align: justify;
  margin-bottom: 5px;
}

.Tech {
  padding-top: 1rem;
}

.Button {
  margin-bottom: 10px;
  border: 2px solid #84670f;
  padding: 5px;
  text-decoration: none;
}

a.Button:hover {
  background-color: #ffc728;
}

a.Button:link {
  text-decoration: inherit;
  color: inherit;
}

a.Button:visited {
  text-decoration: inherit;
  color: inherit;
}


/* 481px—768px: iPads, Tablets */
@media (min-width: 481px) {
  .CardContainer {
    width: 33%;
    height: 300px;
  }

  .Front {
    font-size: 1rem;
  }
}

/* 769px—1024px: Small screens, laptops */
@media (min-width: 769px) {
  .Front {
    font-size: 1.5rem;
  }

  .Back {
    font-size: 1rem;
  }

  .Tech {
    padding: 1rem 0;
  }

  .Description {
    margin-bottom: 15px;
  }
}
