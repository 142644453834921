.Gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

div.Gallery > div:nth-child(even) div:nth-child(1) {
    background-color: #7B5AB3;
    color:white;
}


div.Gallery > div:nth-child(odd) div:nth-child(1) {
    background-color:#ffc000;
}

/* 481px—768px: iPads, Tablets */
@media (min-width: 481px) {
    .Gallery{
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/* 769px—1024px: Small screens, laptops */
@media (min-width: 769px) {
    .Gallery{
        padding: 50px;
    }
}
/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {
    .Gallery{
        max-width: 1500px;
        margin: 0 auto;
        padding: 100px;
    }
}

