.Skills {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  margin-top: 20px;
}

.ProgressBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2em;
  background-image: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.8),
    rgba(0, 0, 0, 0.7)
  );
  border-radius: 0.5rem;
  color: white;
  overflow: hidden;
  margin: 5px;
}

.Progress {
  /* width: 50%; */
  background-image: -webkit-linear-gradient(left, #0db9ff, #1284b3);
}

.Skill {
  width: 80px;
  height: 100%;
  padding-left: 5px;
  padding-top: 5px;
}

.ProgressBar span {
  padding-right: 5px;
  padding-top: 5px;
  padding-left: 5px;
}

#css {
  animation: css-progress 6s forwards;
}

#html {
  animation: html-progress 6s forwards;
}

#react {
  animation: react-progress 6s forwards;
}

#javascript {
  animation: javascript-progress 6s forwards;
}

#nodejs {
  animation: nodejs-progress 6s forwards;
}

@keyframes css-progress {
  from {
    width: 0%;
  }
  to {
    width: 60%;
  }
}

@keyframes html-progress {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}

@keyframes react-progress {
  from {
    width: 0%;
  }
  to {
    width: 80%;
  }
}

@keyframes javascript-progress {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}
@keyframes nodejs-progress {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {
  .Skills {
    width: 50%;
  }
}
