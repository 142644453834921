.Me {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.MePhotoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* padding-left: 20px; */
}

.MeDescriptionContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.MeTitle,
.MeDescription {
  text-align: center;
  margin: 0;
  font-size: 1rem;
}

.MeTitle {
  font-size: 1rem;
  font-weight: bold;
}

.hexa,
.hexa div {
  transform-origin: 50% 50%;
  overflow: hidden;
  width: 300px;
  height: 300px;
}
.hexa {
  width: 290px;
  height: 195px;
}
.hexa div {
  width: 100%;
  height: 100%;
}
.hexa {
  transform: rotate(120deg);
}
.hex1 {
  transform: rotate(-60deg);
}
.hex2 {
  transform: rotate(-60deg);
}

/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {
  .hexa {
    width: 325px;
    height: 230px;
  }

  .Me {
    width: 50%;
  }
}
