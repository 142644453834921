.ContactContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.EnvelopeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 290px;
}
.Envelope {
  width: 300px;
  height: 150px;
  background-color: #84670f;
  position: relative;
  z-index: 1;
}

.Envelope:before {
  content: '';
  top: 0;
  right: 0;
  z-index: 3;
  position: absolute;
  border-left: 300px solid transparent;
  border-bottom: 150px solid #ffc000;
}

.Envelope:after {
  content: '';
  top: 0;
  left: 0;
  z-index: 3;
  position: absolute;
  border-right: 300px solid transparent;
  border-bottom: 150px solid #ffc000;
}

.Back {
  position: absolute;
  top: 0;
  border-top: 70px solid #ffc000;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  transform: rotateX(180deg);
  transform-origin: center top;
  z-index: -1;
  animation: open-envelope 1.5s normal forwards;
}

.Letter {
  position: relative;
  padding: 20px 10px;
  width: 250px;
  margin: 0 auto;
  background-color: #f1ebe3;
  border: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 3px #9d9d9d, inset 0px 0px 27px #fff;
  animation: letter-up 2s normal forwards;
  top: 10px;
  height: 100px;
  overflow: hidden;
  opacity: 0;
}

label {
  color: #816d53;
  margin: 0 20px;
  text-transform: uppercase;
  text-shadow: 0px 1px 0px #fff;
  font-size: 13px;
}

.Button,
input,
textarea {
  width: 90%;
  height: 30px;
  display: block;
  margin: 5px auto;
  padding: 5px;
  background: none;
  outline: none;
  color: #8a775f;
  border: 1px solid #8a775f;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: inset 0px 0px 1px #726959;
  -webkit-box-shadow: inset 0px 0px 1px #b3a895;
  box-shadow: inset 0px 0px 1px #b3a895;
}

textarea {
  height: 100px;
  max-height: 100px;
  max-width: 90%;
  background: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 20px,
    #ddd 20px,
    #ddd 21px
  );
  line-height: 20px;
}

input:focus,
textarea:focus {
  background-color: #f8f4ee;
}

.Button {
  color: #8a775f;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
}

.Button:hover {
  color: #f8f4ee;
  background-color: #84670f;
}

.CloseEnvelope {
  animation: close-envelope 2s 2s normal forwards;
}

@keyframes close-envelope {
  0% {
    transform: rotateX(185deg);
    transform-origin: center top;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: center top;
    z-index: 3;
    border-top: 80px solid #dfa805;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
  }
}

.LetterDown {
  animation: letter-down 2s normal forwards;
}

@keyframes letter-down {
  0% {
    opacity: 1;
    top: -290px;
    height: 380px;
  }
  100% {
    opacity: 1;
    top: 10px;
    height: 100px;
  }
}

@keyframes open-envelope {
  0% {
    transform: rotateX(0deg);
    transform-origin: center top;
  }
  100% {
    transform: rotateX(180deg);
    transform-origin: center top;
    z-index: -1;
  }
}

@keyframes letter-up {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
    top: 10px;
    height: 100px;
  }
  100% {
    opacity: 1;
    top: -290px;
    height: 380px;
    z-index: 2;
  }
}

/* 481px—768px: iPads, Tablets */
@media (min-width: 481px) {
  .ContactContainer {
    padding: 50px;
  }
  .EnvelopeContainer {
    padding-top: 350px;
  }
  .Envelope {
    width: 400px;
    height: 200px;
  }

  .Envelope:before {
    border-left: 400px solid transparent;
    border-bottom: 200px solid #ffc000;
  }

  .Envelope:after {
    border-right: 400px solid transparent;
    border-bottom: 200px solid #ffc000;
  }

  .Back {
    border-top: 150px solid #ffc000;
    border-left: 200px solid transparent;
    border-right: 200px solid transparent;
  }

  .Letter {
    width: 300px;
  }

  @keyframes close-envelope {
    0% {
      transform: rotateX(185deg);
      transform-origin: center top;
    }
    100% {
      transform: rotateX(0deg);
      transform-origin: center top;
      z-index: 3;
      border-top: 100px solid #dfa805;
      border-left: 200px solid transparent;
      border-right: 200px solid transparent;
    }
  }

  @keyframes letter-down {
    0% {
      opacity: 1;
      top: -320px;
      height: 450px;
    }
    100% {
      opacity: 1;
      top: 10px;
      height: 150px;
    }
  }
  @keyframes letter-up {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 1;
      top: 10px;
      height: 150px;
    }
    100% {
      opacity: 1;
      top: -320px;
      height: 450px;
      z-index: 2;
    }
  }
}
/* 1025px—1200px: Desktops, large screens */
@media (min-width: 1025px) {

  .ContactContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }

  .Envelope {
    width: 800px;
    height: 400px;
  }

  .Envelope:before {
    border-left: 800px solid transparent;
    border-bottom: 400px solid #ffc000;
  }

  .Envelope:after {
    border-right: 800px solid transparent;
    border-bottom: 400px solid #ffc000;
  }

  .Back {
    border-top: 300px solid #ffc000;
    border-left: 400px solid transparent;
    border-right: 400px solid transparent;
  }

  .Letter {
    width: 600px;
    height: 500px;
  }

  textarea {
    height: 80px;
  }

  @keyframes close-envelope {
    0% {
      transform: rotateX(180deg);
      transform-origin: center top;
    }
    100% {
      transform: rotateX(0deg);
      transform-origin: center top;
      z-index: 3;
      border-top: 200px solid #dfa805;
      border-left: 400px solid transparent;
      border-right: 400px solid transparent;
    }
  }

  @keyframes letter-down {
    0% {
      opacity: 1;
      top: -300px;
      height: 500px;
    }
    50% {
      opacity: 1;
      top: 10px;
      height: 400px;
    }
    100% {
      opacity: 1;
      top: 10px;
      height: 300px;
    }
  }

  @keyframes open-envelope {
    0% {
      transform: rotateX(0deg);
      transform-origin: center top;
    }
    100% {
      transform: rotateX(180deg);
      transform-origin: center top;
      z-index: -1;
    }
  }
  @keyframes letter-up {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 1;
      height: 200px;
      top: 10px;
    }
    100% {
      opacity: 1;
      top: -300px;
      height: 500px;
      z-index: 2;
    }
  }
} 
